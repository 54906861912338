export const Links = {
    bio: 'https://bio.xalerium.hu/auth/register',
    uploader: 'https://files.xalerium.hu',
    discord: 'https://discord.xalerium.hu',
    shortie: 'https://s.xalerium.hu',
    meowpics: 'https://meow.xalerium.hu',
    style1: 'https://api.xalerium.hu/v2/_next/static/media/xaleriumgg/main/css/cd67Lqwd.css',
    style2: 'https://api.xalerium.hu/v2/_next/static/media/xaleriumgg/main/css/cd77Lqwd.css',
    style3: 'https://api.xalerium.hu/v2/_next/static/media/xaleriumgg/main/css/cd87Lqwd.css',
    style4: 'https://api.xalerium.hu/v2/_next/static/media/xaleriumgg/main/css/cd97Lqwd.css',
    catgif: 'https://api.xalerium.hu/v2/_next/static/media/xaleriumgg/main/images/66b1eb08d9b10.gif'
  };
  
export default Links;