import React from 'react';

interface ButtonProps {
    label: string;
    url: string;
}

const Button: React.FC<ButtonProps> = ({ label, url }) => {
    const handleClick = () => {
        if (url === 'https://discord.xalerium.hu') {
            window.location.href = 'https://discord.gg/tbsp99Cy5J';
        } else {
            window.location.href = url;
        }
    };

    return (
        <div className="button" onClick={handleClick}>
            {label}
        </div>
    );
};

export default Button;