import React from 'react';
import { Links } from './config/urls';
import Button from './components/Button';

const App: React.FC = () => {
    return (
        <div className="main-container">
            <h1 className="header">xalerium</h1>
            <div className="buttons">
                <Button label="bio" url={Links.bio} />
                <Button label="files" url={Links.uploader} />
            </div>
        </div>
    )
}

export default App;

//<Button label="uploader" url={Links.uploader} />
//<Button label="shortener" url={Links.shortie} />
//<Button label="meow.pics" url={Links.meowpics} />